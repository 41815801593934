import { NextPage } from "next";
import { ModeToggle } from "../misc/themeToggler";

interface Props {}

const AuthTopNav: NextPage<Props> = () => {
  return (
    <nav className="flex items-center justify-between py-4">
      <p className="text-base font-semibold text-zinc-700 dark:text-neutral-100">
        amez<span className="font-light tracking-wide">wallet</span>
      </p>
      <ModeToggle />
    </nav>
  );
};

export default AuthTopNav;
