import { z } from "zod";

export const AuthValidator = z.object({
  email: z
    .string({ required_error: "Please enter your email" })
    .min(2, {
      message: "Email address is required.",
    })
    .email({ message: "Enter a valid email address" }),
  password: z
    .string({ required_error: "Please enter a password" })
    .min(8, { message: "Password must be at least 8 characters long" })
    .regex(/[A-Z]/, {
      message: "Password must contain at least one uppercase letter",
    })
    .regex(/[a-z]/, {
      message: "Password must contain at least one lowercase letter",
    })
    .regex(/\d/, { message: "Password must contain at least one number" })
    .regex(/[@$!%*?&#^+=-]/, {
      message: "Password must contain at least one special character",
    }),
});

export type AuthSchema = z.infer<typeof AuthValidator>;
